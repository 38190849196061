/** @jsx jsx */
import * as React from 'react';
import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import ReactJWPlayer from 'react-jw-player';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Button from '../../components/button';
import tw, { styled } from 'twin.macro';
import { jsx, css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { Formik, useFormik } from 'formik';
import { PieChart } from 'react-minimal-pie-chart';
import PromoBlocks from '../../components/standardPromoBlock';
import IndexHero from '../../components/indexHero';
import IndexSwiper from '../../components/indexSwiper';

import * as Yup from 'yup';
import ReactPlayer from 'react-player';
import IndexTestimonialSwiper from '../../components/indexTestimonialSwiper';
import IndexSolutions from '../../components/indexSolutions';
import IndexProducts from '../../components/indexProducts';
import IndexCaseStudies from '../../components/indexCaseStudies';
import IndexBlogs from '../../components/indexBlogs';
import IndexLeadership from '../../components/indexLeadership';
import IndexWhitepaper from '../../components/indexWhitepaper';
import NewEvalForm from '../../components/newEvalForm';
import IndexLogos from '../../components/indexLogos';
import IndexIndustryRecognition from '../../components/indexIndustryRecognition';
import IndexInvestors from '../../components/indexInvestors';
import IndexFreeTrial from '../../components/indexFreeTrial';

// import { Cell, Legend, Label, LabelList, ResponsiveContainer, Pie, PieChart, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .75)',
    zIndex: '999',
  },
  content: {
    background: 'none',
    // backgroundColor: '#000',
    padding: '0',
    // marginTop: "20vh",
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('body');

const VideoPlayer = () => {
  return (
    <div className={''}>
      <ReactPlayer playing url="https://fast.wistia.com/medias/kxiyu3gudy" width={'80vw'} height={'80vh'} />
    </div>
  );
};

function ZycadaModal() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#FFF';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={'hover:mix-blend-multiply bg-violet-700'}>
      <a onClick={openModal}>
        <StaticImage
          src="../../images/cdnReserveThumbnail.png"
          quality={95}
          objectFit={'cover'}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Zycada CDN Reserve"
          styles={{}}
          loading={'eager'}
          placeholder={'none'}
        />
      </a>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Zycada video modal"
        overlayClassName={''}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
        <VideoPlayer></VideoPlayer>
      </Modal>
    </div>
  );
}

const customArchitectureStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .75)',
    zIndex: '999',
  },
  content: {
    background: 'none',
    // backgroundColor: '#000',
    padding: '0',
    // marginTop: "20vh",
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('body');

const ArchitectureImage = () => {
  return (
    <div className={'flex items-center justify-center border border-white rounded-5xl bg-white h-[90vh] w-[90vw]'}>
      <StaticImage
        src={'../../images/cdnReserveReferenceArchitectureBig.png'}
        className={''}
        imgClassName={'w-full'}
        quality={95}
        objectFit={'scale-down'}
        formats={['AUTO', 'WEBP', 'AVIF']}
        alt={'CDN Reserve reference architecture'}
      />
    </div>
  );
};

function ZycadaArchitectureModal() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#FFF';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={'border border-white rounded-5xl hover:border hover:border-zycadaDarkPurple'}>
      <a onClick={openModal}>
        <StaticImage
          src="../../images/cdnReserveReferenceArchitecture.png"
          className={''}
          quality={95}
          objectFit={'cover'}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="CDN Reserve reference architecture"
          styles={{}}
          loading={'eager'}
          placeholder={'none'}
        />
      </a>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={customArchitectureStyles}
        contentLabel="Zycada video modal"
        overlayClassName={''}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
        <ArchitectureImage></ArchitectureImage>
      </Modal>
    </div>
  );
}

const SolutionsCDNReserve = () => (
  <Layout>
    <SEO title="Zycada CDN Reserve provides backup for your CDN with auto failover." />

    <div className="">
      <div className={'py-12'}>
        <div className="row pt-24 ">
          <div className="">
            <div className="container flex flex-col md:flex-row justify-center items-start">
              <div className="row md:w-1/2">
                <div className=" text-center sm:text-left">
                  <h1 className=" display-4 fs-5 text-zycadaDarkPurple ">Zycada CDN Reserve</h1>
                  <h2 className="text-black">
                    <span className=" ">Don't let CDN outages become your crisis</span>
                  </h2>
                  <p className="mt-3 mb-3 text-black">
                    Zycada CDN Reserve rescues your business when your current CDN sinks. It continuously monitors your
                    current CDN and when an outage is detected, it switches traffic to Zycada edge platform.
                  </p>
                  <Link to={'/manage-your-cdn-outage-crisis-during-shopping-season/'}>
                    <button
                      className={
                        'mb-4 mx-2 sm:ml-0 sm:mb-0 min-w-24 max-w-24 relative text-white p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
                      }
                    >
                      FAQ
                    </button>
                  </Link>

                  <Link to={'#get-in-touch'}>
                    <button
                      className={
                        'mb-4 mx-2 sm:mb-0 min-w-24 max-w-24 relative text-white p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
                      }
                    >
                      Contact Us
                    </button>
                  </Link>
                </div>
                <div className="pt-6 text-center align-items-center"></div>
              </div>
              <div className="row md:w-1/2 pt-6 px-6 pb-0">
                <div className="text-center align-items-center">
                  <div className="text-center align-items-center">
                    <ZycadaModal></ZycadaModal>
                  </div>
                </div>
                <div className="pt-6 text-center align-items-center"></div>
              </div>
              <div className="row">
                <div className="pt-4 pb-4 text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={'bg-klean-gradient-bg'}>
      <div className={'flex flex-col flex-wrap align-middle justify-center w-full sm:w-4/5 sm:mx-auto'}>
        <div className={'py-4 flex flex-col flex-wrap justify-center'}>
          <div className={'grid sm:grid-cols-2 lg:grid-cols-4 gap-2'}>
            <div
              className={
                'flex flex-col mx-12 sm:mx-4 my-4 p-6 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <StaticImage
                src="../../images/cdnReserveSetup.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="CDN Reserve"
                className={'mx-16 sm:mx-8'}
              />
              <h4 className="pt-8 text-center">Easy Setup</h4>
              <p className="lg:min-h-20 lg:max-h-20 xl:min-h-12 xl:max-h-12">
                Activates in less than an hour to protect against CDN outages
              </p>
              {/*<Link*/}
              {/*    className={"text-violet-400"}*/}
              {/*    to="/solutions/cdnreserve"*/}
              {/*    rel="prev">*/}
              {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
              {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
              {/*</Link>*/}
            </div>
            <div
              className={
                'flex flex-col mx-12 sm:mx-4 my-4 p-6 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <StaticImage
                src="../../images/cdnReserveAvailability.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="CDN Reserve availability"
                className={'mx-16 sm:mx-8'}
              />
              <h4 className="pt-8 text-center">Max Availability</h4>
              <p className="lg:min-h-20 lg:max-h-20 xl:min-h-12 xl:max-h-12">
                Boost your CDN availability higher than industry average of 98.5%
              </p>
              {/*<Link*/}
              {/*    className={"text-violet-400"}*/}
              {/*    to="/solutions/ecommerce"*/}
              {/*    rel="prev">*/}
              {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
              {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
              {/*</Link>*/}
            </div>
            <div
              className={
                'flex flex-col mx-12 sm:mx-4 my-4 p-6 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <StaticImage
                src="../../images/cdnReserveRisk.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="CDN Reserve risk"
                className={'mx-16 sm:mx-8'}
              />
              <h4 className="pt-8 text-center">Zero Risk</h4>
              <p className="lg:min-h-20 lg:max-h-20 xl:min-h-12 xl:max-h-12">
                Auto-failover and global monitoring eliminate risk
              </p>
              {/*<Link*/}
              {/*    className={"text-violet-400"}*/}
              {/*    to="/solutions/streaming"*/}
              {/*    rel="prev">*/}
              {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
              {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
              {/*</Link>*/}
            </div>
            <div
              className={
                'flex flex-col mx-12 sm:mx-4 my-4 p-6 bg-white/20 rounded-5xl border border-white text-center sm:text-left'
              }
            >
              <StaticImage
                src="../../images/cdnReserveCost.png"
                quality={95}
                objectFit={'contain'}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="CDN Reserve cost"
                className={'mx-16 sm:mx-8'}
              />
              <h4 className="pt-8 text-center">Low Cost</h4>
              <p className="lg:min-h-20 lg:max-h-20 xl:min-h-12 xl:max-h-12">
                Prevent revenue loss from CDN outages for one low price
              </p>
              {/*<Link*/}
              {/*    className={"text-violet-400"}*/}
              {/*    to="/solutions/saas"*/}
              {/*    rel="prev">*/}
              {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
              {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'flex flex-col flex-wrap align-middle justify-center py-8 mx-auto '}>
      <div className={'w-full text-center'}>
        <h1 className={'text-zycadaDarkPurple'}>Pricing</h1>
      </div>

      <div className={'py-4 flex flex-col justify-center'}>
        <div className={'grid sm:grid-cols-2 lg:grid-cols-2 gap-3'}>
          <div
            className={
              'flex flex-col justify-self-center  w-4/5 md:w-2/3 lg:w-4/5 xl:w-1/2 mx-auto sm:mx-4 md:ml-24 md:mr-0  lg:mr-0 xl:ml-72 xl:mr-0 my-4 p-6 bg-zycadaMediumPurple1 rounded-5xl text-center'
            }
          >
            <h3 className="pt-8 text-center text-white">Basic</h3>
            {/* 
            <p className='text-sm mb-1 text-gray-400'>Starts at</p>
            <p className={"text-4xl text-white mb-1"}>
              $60<span className={"text-sm text-white"}>/month</span>{" "}
            </p>
            <p className='text-base mb-1 text-gray-200'>+$0.01 per GB</p>
            */}
            <div className={'bg-white'}>
              <ul className={'pt-2 mx-2'}>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    DNS Auto failover
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Global CDN Monitoring
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Global Origin Monitoring
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Backup CDN
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    DDoS Protection
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Instant Cache Purge
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Default Cache Rules
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faX} className={'text-red-500'} />
                    </span>
                    WAF
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faX} className={'text-red-500'} />
                    </span>
                    Origin Shield
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faX} className={'text-red-500'} />
                    </span>
                    Proactive Cache Warming
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faX} className={'text-red-500'} />
                    </span>
                    No-purge Cache Refresh
                  </p>
                </li>
              </ul>
            </div>

            <Link to={'#contact-us'} className={'pt-4'}>
              <button
                className={
                  'relative text-white px-8 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
                }
              >
                Contact Us
              </button>
            </Link>
          </div>
          <div
            className={
              'flex flex-col justify-self-center w-4/5 md:w-2/3 lg:w-4/5 xl:w-1/2 mx-auto sm:mx-4 md:mr-24 md:ml-0  lg:ml-0 xl:mr-72 xl:ml-0 my-4 p-6 bg-zycadaDarkPurple rounded-5xl text-center'
            }
          >
            <h3 className="pt-8 text-center text-white">Enterprise</h3>
            {/*}
            <p className='text-sm mb-1 text-gray-400'>Starts at</p>
            <p className={"text-4xl text-white mb-1"}>
              $1000<span className={"text-sm text-white"}>/month</span>{" "}
            </p>
            <p className='text-base mb-1 text-gray-200'>+$0.01 per GB</p>
          */}
            <div className={'bg-white'}>
              <ul className={'pt-2 mx-2'}>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    DNS Auto failover
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Global CDN Monitoring
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Global Origin Monitoring
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Backup CDN
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    DDoS Protection
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    Instant Cache Purge
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    <strong>Custom</strong> Cache Rules
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    <strong>Custom</strong> WAF Rules
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    <strong>Origin Shield</strong>
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    <strong>Proactive Cache Warming</strong>
                  </p>
                </li>
                <li className={'border-solid border-b-2'}>
                  <p className={'text-black text-left'}>
                    <span className={'mx-2'}>
                      <FontAwesomeIcon icon={faCheck} className={'text-green-500'} />
                    </span>
                    <strong>No-purge Cache Refresh</strong>
                  </p>
                </li>
              </ul>
            </div>
            <Link to={'#contact-us'} className={'pt-4'}>
              <button
                className={
                  'relative text-white px-8 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
                }
              >
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className={'w-4/5 xl:w-3/5 flex flex-col flex-wrap align-middle justify-center py-8 mx-auto '}>
        <div className={'w-full text-center'}>
          <h1 className={'text-zycadaDarkPurple'}>Challenges with implementing a backup CDN</h1>
        </div>
        <div className={'flex flex-col sm:flex-row xl:flex-wrap justify-center my-auto'}>
          <div className={'p-2 mx-auto my-auto sm:mr-4 sm:ml-4'}>
            <StaticImage
              src={'../../images/cdnReserveIntegrate.png'}
              className={'border border-white rounded-5xl'}
              quality={95}
              height={250}
              objectFit={'contain'}
              formats={['PNG', '', '']}
              alt={'CDN Reserve integration'}
            />
          </div>
          <div className={'w-4/5 md:w-3/5 mx-auto pb-8 pt-8 sm:px-4 md:px-4 lg:pr-0'}>
            <h3 className={'text-zycadaDarkPurple'}>Difficult to integrate three different vendors</h3>
            <p className={'text-base'}>
              Effective backup CDN implementation needs three fundamental services. Today, customers have the burden to
              subscribe and manage these services independently.
            </p>
            <ol className={'pt-2 mx-2 list-decimal'}>
              <li className={''}>
                <strong>Backup CDN</strong> handles traffic when the primary CDN goes down
              </li>
              <li className={''}>
                <strong>Monitor service</strong> checks CDN health continuously (Catchpoint, Pingdom ...)
              </li>
              <li className={''}>
                <strong>DNS provider</strong> switches traffic between primary and backup CDN (Route53, NS1 ...)
              </li>
            </ol>
          </div>
        </div>

        <div className={'flex flex-col-reverse sm:flex-row xl:flex-wrap justify-center my-auto'}>
          <div className={'w-4/5 md:w-3/5 mx-auto pb-8 pt-8 sm:px-4 md:px-4 lg:pr-0'}>
            <h3 className={'text-zycadaDarkPurple'}>Tough to maintain parity between CDNs</h3>
            <p className={'text-base'}>Backup CDNs should be on par with the current CDN to handle full traffic.</p>
            <ol className={'pt-2 mx-2 list-decimal'}>
              <li className={''}>
                <strong>PCI/DSS compliant</strong> edge platform to handle sensitive data traffic
              </li>
              <li className={''}>
                <strong>SLA guarantee</strong> for capacity, performance and security
              </li>
              <li className={''}>
                <strong>Operations workflow</strong> similar to that of current CDN
              </li>
            </ol>
          </div>
          <div className={'p-2 mx-auto my-auto sm:mr-4 sm:ml-4'}>
            <StaticImage
              src={'../../images/cdnReserveNoParity.png'}
              className={'border border-white rounded-5xl'}
              quality={95}
              height={250}
              objectFit={'contain'}
              formats={['JPEG', '', '']}
              alt={'CDN Reserve parity'}
            />
          </div>
        </div>

        <div className={'flex flex-col sm:flex-row xl:flex-wrap justify-center my-auto'}>
          <div className={'p-2 mx-auto  my-auto sm:mr-4 sm:ml-4'}>
            <StaticImage
              src={'../../images/cdnReserveHighCost.png'}
              className={'border border-white rounded-5xl'}
              quality={95}
              height={250}
              objectFit={'contain'}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt={'CDN Reserve high cost'}
            />
          </div>
          <div className={'w-4/5 md:w-3/5 mx-auto pb-8 pt-8 sm:px-4 md:px-4 lg:pr-0'}>
            <h3 className={'text-zycadaDarkPurple'}>Very expensive and time-consuming</h3>
            <p className={'text-base'}>It takes a lot of time and resources to setup and operate backup CDN.</p>
            <ol className={'pt-2 mx-2 list-decimal'}>
              <li className={''}>
                <strong>High Services Cost</strong> for three services from different vendors
              </li>
              <li className={''}>
                <strong>Long Setup Time</strong> for integrating those services at global scale
              </li>
              <li className={''}>
                <strong>More Ops Cost</strong> to manage and operate two CDNs
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className={'w-full flex flex-col flex-wrap align-middle justify-center py-8 mx-auto '}>
        <div className={'bg-klean-gradient-bg'}>
          <div className={'flex flex-col flex-wrap align-middle justify-center w-full sm:w-4/5 sm:mx-auto'}>
            <div className={'w-full text-center pt-8'}>
              <h1 className={'text-white'}>Solution</h1>
              <h1 className={'text-white'}>Zycada CDN Reserve</h1>
            </div>

            <div className={'py-4 flex flex-col flex-wrap justify-center'}>
              <div className={'grid sm:grid-cols-1 lg:grid-cols-3 gap-2'}>
                <div className={'flex flex-col mx-12 sm:mx-4 my-4 p-6 text-center sm:text-left'}>
                  <StaticImage
                    src="../../images/cdnReserveComprehensiveIntegrated.png"
                    quality={95}
                    objectFit={'contain'}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="CDN Reserve is comprehensive and integrated"
                    className={'mx-16 sm:mx-8'}
                  />
                  <h3 className="pt-8 text-center text-white">Comprehensive & Integrated</h3>
                  <ul className={'pt-2 mx-2 list-disc'}>
                    <li className={'text-white'}>
                      End-to-end transparent solution to boost your CDN availability above industry average
                    </li>
                    <li className={'text-white'}>
                      Detects outages, switches traffic automatically and notifies customers in real-time
                    </li>
                    <li className={'text-white'}>
                      Fully integrated with an intelligent edge platform, reliable DNS service and global monitoring
                    </li>
                  </ul>
                  {/*<Link*/}
                  {/*    className={"text-violet-400"}*/}
                  {/*    to="/solutions/cdnreserve"*/}
                  {/*    rel="prev">*/}
                  {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
                  {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
                  {/*</Link>*/}
                </div>
                <div className={'flex flex-col mx-12 sm:mx-4 my-4 p-6 text-center sm:text-left'}>
                  <StaticImage
                    src="../../images/cdnReserveDependableScalable.png"
                    quality={95}
                    objectFit={'contain'}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="CDN Reserve is dependable and scalable"
                    className={'mx-16 sm:mx-8'}
                  />
                  <h3 className="pt-8 text-center text-white">Dependable & Scalable</h3>
                  <ul className={'pt-2 mx-2 list-disc'}>
                    <li className={'text-white'}>
                      PCI/DSS compliant platform for eCommerce and SaaS traffic with sensitive data
                    </li>
                    <li className={'text-white'}>
                      Protects your web applications with OWASP 2.0 compliant WAF and DDoS mitigation
                    </li>
                    <li className={'text-white'}>
                      3rd largest network infrastructure in the US in terms of peering (4th largest in the world)
                    </li>
                  </ul>
                  {/*<Link*/}
                  {/*    className={"text-violet-400"}*/}
                  {/*    to="/solutions/ecommerce"*/}
                  {/*    rel="prev">*/}
                  {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
                  {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
                  {/*</Link>*/}
                </div>
                <div className={'flex flex-col mx-12 sm:mx-4 my-4 p-6 text-center sm:text-left'}>
                  <StaticImage
                    src="../../images/cdnReserveEfficient.png"
                    quality={95}
                    objectFit={'contain'}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="CDN Reserve is cost-efficient"
                    className={'mx-16 sm:mx-8'}
                  />
                  <h3 className="pt-8 text-center text-white">Cost-effective & Turn-key</h3>
                  <ul className={'pt-2 mx-2 list-disc'}>
                    <li className={'text-white'}>
                      Cost-effective solution includes CDN, DNS and Monitor services for one low price
                    </li>
                    <li className={'text-white'}>
                      Reduces recurring Ops cost with seamless operations for cache and security management
                    </li>
                    <li className={'text-white'}>
                      Quick and easy setup to start boosting your CDN availability in less than 60 minutes
                    </li>
                  </ul>
                  {/*<Link*/}
                  {/*    className={"text-violet-400"}*/}
                  {/*    to="/solutions/streaming"*/}
                  {/*    rel="prev">*/}
                  {/*    Learn more&nbsp;&nbsp;&nbsp;*/}
                  {/*    <FontAwesomeIcon icon={faArrowRight}/>*/}
                  {/*</Link>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'flex flex-col md:flex-row flex-wrap w-full md:w-4/5 justify-center mx-auto py-8'}>
          <div className={'w-full text-center'}>
            <h1 className={'text-zycadaDarkPurple'}>Products</h1>
            <p className={'text-black'}>Zycada CDN Reserve includes these products</p>
          </div>
          {/*<div className={"flex flex-col md:flex-row justify-center items-center"}>*/}

          <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
            <div className={'flex flex-col items-center w-full'}>
              <div className={'flex justify-center'}>
                <div className={'zycadaIconWrapper zycadaShadowSecondary flex pb-8 text-center'}>
                  <div className={'zycadaIcon text-violet-400'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-check-circle"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                      <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                  </div>
                </div>
              </div>
              <h3 className={'text-base text-center'}>Cloud Service Monitor</h3>
              <p className={'text-center min-h-8 max-h-8'}>
                Monitors cloud application and network health without client instrumentation
              </p>
            </div>
          </div>
          <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
            <div className={'flex flex-col items-center w-full'}>
              <div className={'flex justify-center'}>
                <div className={'zycadaIconWrapper zycadaShadowSecondary flex pb-8 text-center'}>
                  <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-globe"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="2" y1="12" x2="22" y2="12"></line>
                      <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <h3 className={'text-base text-center'}>DNS Director</h3>
              <p className={'text-center min-h-8 max-h-8'}>
                Increases service availability with self healing and auto-failover capabilities
              </p>
            </div>
          </div>
          <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
            <div className={'flex flex-col items-center w-full'}>
              <div className={'flex justify-center'}>
                <div className={'zycadaIconWrapper zycadaShadowSecondary flex pb-8 text-center'}>
                  <div className={'zycadaIcon text-violet-400'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-file"
                    >
                      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                      <polyline points="13 2 13 9 20 9"></polyline>
                    </svg>
                  </div>
                </div>
              </div>
              <h3 className={'text-base text-center'}>Content Delivery</h3>
              <p className={'text-center min-h-8 max-h-8'}>
                Caches and accelerates videos, images and digital assets efficiently at the edge
              </p>
            </div>
          </div>
          <div className={'w-full md:w-1/2 lg:w-1/4 py-2 px-4'}>
            <div className={'flex flex-col items-center w-full'}>
              <div className={'flex justify-center'}>
                <div className={'flex items-center zycadaIconWrapper zycadaShadowSecondary pb-8 text-center'}>
                  <div className={'zycadaIcon text-violet-400 min-h-10 max-h-10'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-shield"
                    >
                      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <h3 className={'text-base text-center'}>Cloud Service Shield</h3>
              <p className={'text-center min-h-8 max-h-8'}>
                Improves security index without slowing app responsiveness
              </p>
            </div>
          </div>
        </div>

        <div className={'flex flex-col flex-wrap w-full md:w-4/5 justify-center mx-auto py-8'}>
          <div className={'w-full text-center'}>
            <h1 className={'text-zycadaDarkPurple'}>How does CDN Reserve work?</h1>
          </div>
          {/*<div className={"flex flex-col md:flex-row justify-center items-center"}>*/}

          <div className={'w-full py-2 px-4 text-center'}>
            <div className={'p-2 mx-auto'}>
              <ZycadaArchitectureModal></ZycadaArchitectureModal>
            </div>
          </div>
          <div className={' mx-4 sm:text-left'}>
            <p className={'text-base'}>
              Zycada CDN Reserve maps 99% of traffic to your current CDN and 1% traffic to Zycada Edge Platform. Under
              normal conditions, for 1% traffic, Zycada edge platform routes all dynamic traffic to your current CDN and
              serves static content from it's cache. However, static content is refreshed from your current CDN for
              every request.
            </p>

            <p className={'text-base'}>
              Cloud Service Monitor continuously monitors your service on the Zycada edge platform, as well as your
              current CDN. DNS Director uses this monitoring data to detect problems and take corrective actions. When
              your current CDN suffers an outage, it automatically switches 100% traffic to Zycada edge platform. During
              the outage, Zycada routes all dynamic traffic to your origin and serves static from it's cache. As soon as
              your current CDN recovers from outage, Zycada switches 99% traffic back to your current CDN.
            </p>

            <p className={'text-base'}>You can activate Zycada CDN Reserve in less than an hour with 2 simple steps.</p>
            <div>
              <ol className={'pt-2 mx-4 list-decimal'}>
                <li className={''}>Configure your service in Zycada edge platform. (less than 30 minutes)</li>
                <li className={''}>Set your domain's DNS entry to Zycada CNAME. (less than minutes)</li>
              </ol>
            </div>
          </div>
          <div className={'text-center mx-4 sm:text-left'}>
            <Link to={'/manage-your-cdn-outage-crisis-during-shopping-season/'}>
              <button
                className={
                  'min-w-24 max-w-24 relative text-white  p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
                }
              >
                FAQ
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>

    <IndexFreeTrial />

    {/*<p>*/}
    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    {/*</p>*/}
  </Layout>
);

export default SolutionsCDNReserve;
